"use client";
import React from "react";
import { Button } from "antd";
import Link from "next/link";
import { useMediaQueryList } from "../lib/hooks/media.query";

const SocialShareButtons: React.FC = () => {
  const { isMobile } = useMediaQueryList();
  const url = window.location.href;
  const text = "TopSlctd";
  const description = "TopSlctd online shopping website";
  const imageUrl = `${window.location.origin}/favicon.ico`;
  return (
    <>
      <Link
        href={`https://t.me/share/url?url=${url}&text=${text}`}
        title="Share this page with friends on Telegram"
        target="_blank"
      >
        <Button
          type="primary"
          icon={
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 455.731 455.731"
              xmlSpace="preserve"
              width="45px"
              height="45px"
              fill="#000000"
            >
              <path
                style={{ fill: "#FFFFFF" }}
                d="M358.844,100.6L54.091,219.359c-9.871,3.847-9.273,18.012,0.888,21.012l77.441,22.868l28.901,91.706 c3.019,9.579,15.158,12.483,22.185,5.308l40.039-40.882l78.56,57.665c9.614,7.057,23.306,1.814,25.747-9.859l52.031-248.76 C382.431,106.232,370.443,96.08,358.844,100.6z M320.636,155.806L179.08,280.984c-1.411,1.248-2.309,2.975-2.519,4.847 l-5.45,48.448c-0.178,1.58-2.389,1.789-2.861,0.271l-22.423-72.253c-1.027-3.308,0.312-6.892,3.255-8.717l167.163-103.676 C320.089,147.518,324.025,152.81,320.636,155.806z"
              ></path>
            </svg>
          }
          style={{
            position: "fixed",
            bottom: isMobile ? "179px" : "349px",
            right: isMobile ? "4px" : "20px",
            zIndex: 1000,
            width: isMobile ? "45px" : "45px",
            height: isMobile ? "45px" : "45px",
            borderRadius: isMobile ? "50%" : 0,
          }}
          size="large"
        />
      </Link>
      <Link
        href={`https://vk.com/share.php?url=${url}&title=${text}&image=${imageUrl}&description=${description}`}
        title="Share this page with friends on VKontakte"
        target="_blank"
      >
        <Button
          type="primary"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="45px"
              height="45px"
              fill="#000000"
            >
              <g>
                <path
                  fill="#ffffff"
                  d="M274 363c5-1 14-3 14-15 0 0-1-30 13-34s32 29 51 42c14 9 25 8 25 8l51-1s26-2 14-23c-1-2-9-15-39-42-31-30-26-25 11-76 23-31 33-50 30-57-4-7-20-6-20-6h-57c-6 0-9 1-12 6 0 0-9 25-21 45-25 43-35 45-40 42-9-5-7-24-7-37 0-45 7-61-13-65-13-2-59-4-73 3-7 4-11 11-8 12 3 0 12 1 17 7 8 13 9 75-2 81-15 11-53-62-62-86-2-6-5-7-12-9H79c-6 0-15 1-11 13 27 56 83 193 184 192z"
                ></path>
              </g>
            </svg>
          }
          style={{
            position: "fixed",
            bottom: isMobile ? "130px" : "300px",
            right: isMobile ? "4px" : "20px",
            zIndex: 1000,
            width: isMobile ? "45px" : "45px",
            height: isMobile ? "45px" : "45px",
            borderRadius: isMobile ? "50%" : 0,
          }}
          size="large"
        />
      </Link>
    </>
  );
};

export default SocialShareButtons;
