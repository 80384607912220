import { createSlice } from "@reduxjs/toolkit";
import { Mock_CategoriesData } from "../_mock/categories";

export type CategoriesSliceType = {
  categories: MenuItem[];
  categoriesForNavbar: MenuItem[];
  categoriesTrans: CategoryTransType[];
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    categories: [] as MenuItem[],
    flatCategoriesForNavbar: [] as MenuItem[],
    categoriesTrans: [] as CategoryTransType[],
  },
  reducers: {
    updateCategories: (state, data) => {
      state.categories = data.payload;
    },
    updateFlatCategoriesForNavbar: (state, data) => {
      state.flatCategoriesForNavbar = data.payload;
    },
    updateCategoriesTrans: (state, data) => {
      state.categoriesTrans = data.payload;
    },
  },
});

export const {
  updateCategories,
  updateFlatCategoriesForNavbar,
  updateCategoriesTrans,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
