export namespace Constants {
  export enum ZIndex {
    STICKY = 1000,
    POPUP = 1001,
    DROPDOWNMENU = 900,
  }
  export const ItemsPerPage = 12;
  export const MaxInfiniteScrollPageNoAllowed = 200;
  export const NoImageFound = "/no-image-to-show.jpg";
}
