export namespace StaticLinks {
  export const Homepage = "/";
  export const Category = "/search1688?categoryId=";
  export const SearchCollection = "/search1688?productCollectionId=";
  export const Search = "/search1688";
  export const SearchPartner = "/search/partner/";
  export const SearchAllPartners = "/search/partner";
  export const TopRank = "/toprank";
  // imageAddress必须是1688的产品图片
  export const SearchImageFrom1688 = "/search1688?imageAddress=";
  export const SearchImageUpload = "/search1688?imageId=";
  export const Offer = "/offer";
  export const User = "/user";
  export const Login = "/user/login";
  export const LoginWithRedirect = "/user/login?redirectTo=";
  export const Order = "/user/order/list";
  export const OrderShippingAddress = "/user/account/shipping";
  export const ShoppingCart = "/user/shopping/cart";
  export const Shipping = "/user/account/shipping";
  export const Posts = "/company/posts/";
  export const Tutorials = "/company/tutorials/";
  export const AccountSetting = "/user/account/setting";
}
