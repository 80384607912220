// offer 相关接口

import { Constants } from "../constant";
import { apiClient } from "../util/http";

class Offer {
  private offerListURL: string = "/web/offer/list";

  private getOfferList(
    page: number,
    size: number,
    params?: { [key: string]: any }
  ): Promise<
    InterfaceBasicResponseDataType & {
      rows: {
        pageNum: number;
        pageSize: number;
        total: number;
        hasNext: boolean;
        rows: OfferExtType[][];
      };
    }
  > {
    try {
      return apiClient.get(this.offerListURL, {
        pageNum: page,
        pageSize: size,
        ...params,
      });
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  syncOffer(offerId: string): Promise<InterfaceBasicResponseDataType> {
    try {
      return apiClient.get("/web/sync_offer/" + offerId);
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  search1688(params: Search1688ApiParams): Promise<
    InterfaceBasicResponseDataType & {
      data: {
        totalRecords: number;
        totalPage: number;
        pageSize: number;
        currentPage: number;
        data: Search1688ItemType[];
      };
    }
  > {
    try {
      return apiClient.post("/web/offer/search1688", params);
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  productSearchImageQuery(params: ImageSearch1688ApiParams): Promise<
    InterfaceBasicResponseDataType & {
      data: {
        totalRecords: number;
        totalPage: number;
        pageSize: number;
        currentPage: number;
        data: Search1688ItemType[];
        picRegionInfo: {
          currentRegion: string;
          yoloCropRegion: string;
        };
      };
    }
  > {
    try {
      return apiClient.post("/web/offer/product_search_image_query", params);
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  productImageUpload(params: ProductImageUploadParams): Promise<
    InterfaceBasicResponseDataType & {
      data: string;
    }
  > {
    try {
      return apiClient.post("/web/offer/product_image_upload", params);
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  productTopListQuery1688(params: ProductTopListQueryParamsType): Promise<
    InterfaceBasicResponseDataType & {
      data: ProductTopListQueryResponseType;
    }
  > {
    try {
      return apiClient.post("/web/offer/product_top_list_query1688", params);
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  offerRecommend1688(params: OfferRecommendParams): Promise<
    InterfaceBasicResponseDataType & {
      data: {
        result: OfferRecommend1688ItemType[];
      };
    }
  > {
    try {
      return apiClient.post("/web/offer/offer_recommend_1688", params);
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  async queryOffer(
    pageNo: number,
    pageSize: number = Constants.ItemsPerPage,
    params: { [key: string]: any } = {}
  ) {
    const resp = await offerApi.getOfferList(pageNo, pageSize, {
      ...params,
    });
    const items = resp.rows;

    return {
      items,
      hasMore: resp.rows.total > items.rows.length,
    };
  }

  queryRandomOffersByCategoryId(
    categoryId: number,
    returnCount: number
  ): Promise<
    InterfaceBasicResponseDataType & { rows: { rows: OfferExtType[][] } }
  > {
    try {
      return apiClient.get(
        `/web/offers/${categoryId}?return_count=${returnCount}`
      );
    } catch (e) {
      console.log(e);
      return new Promise([] as any);
    }
  }

  async queryOfferDetail(offerId: number): Promise<
    InterfaceBasicResponseDataType & {
      pageNum: number;
      pageSize: number;
      total: number;
      hasNext: boolean;
      data: {
        rows: OfferExtType[][];
      };
    }
  > {
    try {
      return apiClient.get(`/web/offer/${offerId}`);
    } catch (e) {
      console.log(e);
      return new Promise([] as any);
    }
  }

  async queryProductRelatedRecommend(offerId: number): Promise<
    InterfaceBasicResponseDataType & {
      pageNum: number;
      pageSize: number;
      total: number;
      data: ProductRelatedRecommendType[];
    }
  > {
    try {
      return apiClient.get(
        `/web/offer/product_related_recommend?offerId=` + offerId
      );
    } catch (e) {
      console.log(e);
      return new Promise([] as any);
    }
  }

  async queryProductHotSearchKeywords(
    cateId: number,
    lang: string
  ): Promise<
    InterfaceBasicResponseDataType & {
      pageNum: number;
      pageSize: number;
      total: number;
      data: ProductHotSearchKeywordsType[];
    }
  > {
    try {
      return apiClient.get(
        `/web/category_hot_search_keywords?cateId=${cateId}&lang=${lang}`
      );
    } catch (e) {
      console.log(e);
      return new Promise([] as any);
    }
  }

  async queryPartnerNameList(): Promise<
    InterfaceBasicResponseDataType & {
      data: string[];
    }
  > {
    try {
      return apiClient.get(`/web/partner_name_list`);
    } catch (e) {
      console.log(e);
      return new Promise([] as any);
    }
  }
}

export const offerApi = new Offer();
