import { apiClient } from "../util/http";

class Configuration {
  private configurationHomepageUrl: string = "/web/configuration/homepage";
  private configurationCategoriesLanguagesUrl: string =
    "/web/configuration/categories_languages";

  getConfigurationHomepage(type = "commercial"): Promise<
    InterfaceBasicResponseDataType & {
      data: APIConfigurationResponseType;
    }
  > {
    try {
      return apiClient.get(this.configurationHomepageUrl, { type });
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }
  getConfigurationCategoriesLanguages(): Promise<
    InterfaceBasicResponseDataType & {
      data: APIConfigurationResponseType;
    }
  > {
    try {
      return apiClient.get(this.configurationCategoriesLanguagesUrl);
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }
}

export const configurationApi = new Configuration();
