import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMessage } from "../ws/message.parser";

export type WebSocketSliceType = {
  message: IMessage;
};
const initialState = {
  message: {
    session_id: undefined,
    user_id: undefined,
    new_posts: [],
    page_refresh_required: false,
    logout_required: false,
    cart_info: undefined,
  } as IMessage,
};

export const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<IMessage | null>) => {
      state.message = {
        ...state.message,
        ...(action.payload || initialState.message),
      };
    },
  },
});

export const { setMessage } = websocketSlice.actions;

export default websocketSlice.reducer;
